import React from "react";
import Link from "gatsby-link";

function loadSubToMe() {
  const z = document.createElement("script");
  z.src = "https://www.subtome.com/load.js";
  document.body.appendChild(z);
}

const Archives = (props) => (
  <section className="archives wide">
    <div className="container cf">
      <h3>Blog Archives:</h3>
      <nav className="post-archives" role="navigation">
        <ul className="list-unstyled list-inline">
          { props.archiveYears.map(archiveYear => (
            <li key={archiveYear}><Link to={`/archives/${archiveYear}`}>{archiveYear}</Link></li>
          ))
          }
        </ul>
      </nav>
      <div className="rss">
        <button
          onClick={loadSubToMe}
          value="Subscribe to RSS feed"
          className="button button-small"
        >
          Subscribe to RSS feed
        </button>
      </div>
    </div>
  </section>
);

export default Archives;
