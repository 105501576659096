import React from "react";
import Link from "gatsby-link";

const Header = (props) => (
  <header className={props.className}>
    <div id="nav" className="menu">
      <div className="navigation header-has-logo">
        <div className="cf">
          <h1 className="pull-left">
            <a
              href="//andyet.com"
              className="logo andyet-logo transition transition-fadeout"
            >
              &yet
            </a>
          </h1>
          <nav role="navigation" className="pull-right top-nav top-nav-light">
            <input id="menu-toggle" type="checkbox" className="menu-toggle" />
            <label htmlFor="menu-toggle" className="pull-right">
              Menu
            </label>
            <ul className="list-unstyled list-inline no-margin-bottom cf">
              <li>
                <a href="//andyet.com/services">Services</a>
              </li>
              <li>
                <a href="//andyet.com/about">About</a>
              </li>
              <li>
                <a href="//andyet.com/contact">Contact</a>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div role="banner" className="banner wide">
      <Link to="/">
        <h2 className="headline-main">The Blog</h2>
      </Link>
    </div>
  </header>
);

export default Header;
