import React, { Fragment } from "react";
import Link from "gatsby-link";

const PostList = (props) => {
  return (
    <Fragment>
      {props.posts.map(({ node }) => (
        <Fragment key={node.fields.permalink}>
          <article className="post">
            <h2 className="postTitle">
              <Link className="permalink" to={node.fields.permalink}>
                {node.frontmatter.title}
              </Link>
            </h2>
            <div className="meta cf">
              <span>
                <Link className="permalink" to={node.fields.permalink}>
                  {node.frontmatter.formattedDate}
                </Link>
              </span>
              <span><a
                  href={`https://andyet.com/team/${
                    node.frontmatter.author_slug
                  }`}
                >
                  {node.frontmatter.author}
                </a>
              </span>
            </div>
            <div
              className="postContent"
              dangerouslySetInnerHTML={{ __html: node.formattedExcerpt }}
            />
            <div className="postContent">
              <Link className="continue-reading" to={node.fields.permalink}>
                {" "}
                Continue reading »
              </Link>
            </div>
          </article>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PostList;
