import React from "react";
import Link from "gatsby-link";

const Paginator = ({ index, pageCount, olderUrl, newerUrl }) => (
  <nav role="navigation" className="center">
    <ul className="list-unstyled list-inline pagination">
      <li className="older">{index !== pageCount ? <Link to={olderUrl}>« Older</Link> : null}</li>
      <li className="page-count">{`Page ${index} of ${pageCount}`}</li>
      <li className="newer">{index > 1 ? <Link to={newerUrl}>Newer »</Link> : null}</li>
    </ul>
  </nav>
);

export default Paginator;
