import React, { Fragment } from "react";
import Header from "../components/Header";
import Archives from "../components/Archives";
import Footer from "../components/Footer";
import Typekit from "../components/Typekit";
import { Helmet } from "react-helmet";
import "../styl/main.styl";

const DefaultLayout = (props) => (
  <Fragment>
    <Helmet>
      <link rel="shortcut icon" href="/images/ampersand.png" />
      <meta name='description' content="The blog of &yet, a design and web software company that strengthens your customer relationships through creative technology." />
    </Helmet>
    <Typekit id="gqk8csn" />
    <main className={props.mainClassName}>
      <Header className={props.headerClassName} />
      <div className="container">
        <div className="content" role="main">
          {props.children}
        </div>
      </div>
      <Archives archiveYears={props.archiveYears} />
    </main>
    <Footer />
  </Fragment>
);

export default DefaultLayout;
