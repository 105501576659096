import React from "react";
import Link from "gatsby-link";

const Footer = () => (
  <footer role="contentinfo" className="footer-primary wide">
    <div className="container cf">
      <a
        href="/"
        title="&amp;yet's Home Page"
        className="logo ampersand-logo transition transition-fadeout pull-left"
      >
        &yet
      </a>
      <div className="pull-left">
        <nav role="navigation">
          <ul className="list-inline list-unstyled cf">
            <li>
              <a href="//andyet.com/services">Services</a>
            </li>
            <li>
              <a href="//andyet.com/about">About</a>
            </li>
            <li>
              <a href="//andyet.com/contact">Contact</a>
            </li>
            <li>
              <Link to="/">Blog</Link>
            </li>
            <li>
              <a href="//andyet.com/privacy">Privacy</a>
            </li>
            <li>
              <a href="//gatherthepeople.com">Gather The People</a>
            </li>
            <li>
              <a href="//twitter.com/andyet">@andyet</a>
            </li>
            <li>
              <a href="//andyet.com/warm-cookies"><span role="img" aria-label="cookie">🍪</span></a>
            </li>
          </ul>
          <ul id="contact-info" className="list-inline list-unstyled cf">
            <li>
              <a href="https://www.google.com/maps/place/723+The+Parkway,+Richland+WA+99352">
                723 The Parkway Suite &, Richland, WA 99352
              </a>
            </li>
            <li>
              <a href="tel:+15094166186">+1 509 416 6186</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

export default Footer;
